<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="white-space: nowrap;font-weight: 700;">关键字: </label>
				<el-input v-model="keywords" placeholder="手机号、微信昵称、姓名" style="width: 240px;"></el-input>
				<label class="label" style="white-space: nowrap;font-weight: 700;margin-left: 20px;">收支时间: </label>
				<el-date-picker v-model="startTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
					placeholder="开始日期">
				</el-date-picker>
				~
				<el-date-picker v-model="endTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="结束日期">
				</el-date-picker>
			</div>
			<div class="filter-item">
				<el-button type="primary" @click="handleSearch">查询</el-button>
				<buttonPermissions :datas="mod === '0' ? 'adjustment' : 'agentAdjustment'">
					<el-button type="primary" @click="showEdit" style="margin-left: 10px;">收支调整</el-button>
				</buttonPermissions>
				<buttonPermissions
					:datas="mod === '0' ? 'receiptAndPaymentRecordExport' : 'agentReceiptAndPaymentRecordExport'">
					<el-button @click="exportFn" style="margin-left: 10px;">导出</el-button>
				</buttonPermissions>
			</div>
		</div>


		<el-table :data="list" style="width: 100%">
			<el-table-column prop="Name" label="客户">
				<template slot-scope="scope">
					<div class="member-info">
						<div class="hd">
							<img :src="scope.row.WxHeadUrl || defaultHeader">
						</div>
						<div class="info">
							<div class="name" style="font-size: 15px;">{{scope.row.Name || scope.row.WxNickname}}</div>
							<div class="name">
								{{scope.row.Phone?scope.row.Phone:''}}
								<!-- {{scope.row.Phone}} -->
							</div>
						</div>
					</div>
				</template>
			</el-table-column>
			<!-- 新增判断 -->
			<el-table-column prop="VipRoleName" label="会员级别">
				<template slot-scope="scope">
                    {{scope.row.VipRoleName?scope.row.VipRoleName:"--" }}
				</template>     
			</el-table-column>
			<!-- 新增判断 -->
			<el-table-column prop="RoleName" label="推手级别">
                <template slot-scope="scope">
                    {{scope.row.RoleName?scope.row.RoleName:"--" }}
				</template> 
			</el-table-column>
			<el-table-column prop="ChangeWaitDrawBalance" label="收支金额">
				<template slot-scope="scope">
					<div>
						<span v-if="scope.row.ChangeWaitDrawBalance < 0"
							style="color: #DD3131;">{{scope.row.ChangeWaitDrawBalance}}</span>
						<span v-else>{{scope.row.ChangeWaitDrawBalance}}</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="AddTime" label="收支时间"></el-table-column>
			<el-table-column prop="Remark" label="收支备注"></el-table-column>
		</el-table>

		<div class="pagination-wraper">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="pageIndex" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>

		<el-dialog :visible.sync="visible" title="收支调整" @close="visible=false" width="600px">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px">
				<el-form-item label="查找推手:" prop="phone">
					<el-input v-model="ruleForm.phone" maxlength="11" style="width: 300px;" placeholder="输入推手手机号">
					</el-input>
				</el-form-item>
				<el-form-item label="收支调整:" prop="type" style="width: 500px;">
					<el-radio-group v-model="ruleForm.type">
						<el-radio :label="9">充值可提现收益</el-radio>
						<el-radio :label="10">扣减可提现收益</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="调整金额:" prop="money" v-if="ruleForm.type">
					<el-input v-model="ruleForm.money" style="width: 300px;" placeholder="请输入金额"></el-input>
				</el-form-item>
				<el-form-item label="备注:" prop="remark" v-if="ruleForm.type">
					<el-input type="textarea" :rows="4" maxlength="50" v-model="ruleForm.remark" style="width: 300px;"
						placeholder="请输入备注">
					</el-input>
				</el-form-item>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="visible = false">取 消</el-button>
				<el-button type="primary" @click="handleSubmit">确 定</el-button>
			</span>

		</el-dialog>

	</div>
</template>

<script>
	import {
		membercommissionbalancerecordpagelist,
		memberCommissionChange,
		memberinfobyphone
	} from '@/api/api.js';
	import config from '@/config/index';
	import {
		validatePhone
	} from '@/utils/validate.js'

	import buttonPermissions from '@/components/buttonPermissions';

	export default {
		components: {
			buttonPermissions
		},
		data() {

			var validMoney = (rule, value, callback) => {
				var valStr = value + '';
				if (value === undefined || value === '' || isNaN(value)) {
					callback(new Error('金额请输入在0.01~9999之间，最多2位小数'));
				} else if (valStr.split(".")[1] && valStr.split(".")[1].length>2){
					callback(new Error('金额请输入在0.01~9999之间，最多2位小数'));
				} else if (value > 9999 || value < 0.01){
					callback(new Error('金额请输入在0.01~9999之间，最多2位小数'));
				}else {
					callback();
				}
			};

			return {
				defaultHeader: config.DEFAULT_HEADER,
				exportUrl: config.EXPORT_URL,
				mod: 0,
				keywords: '',
				time: '',
				startTime: '',
				endTime: '',
				pageIndex: 1,
				pageSize: 20,
				total: 0,
				list: [],
				visible: false,
				ruleForm: {
					phone: '',
					type: '',
					money: undefined,
					remark: ''
				},
				rules: {
					phone: [{
						required: true,
						message: '请输入推手手机号',
						trigger: 'blur',
					}],
					type: [{
						required: true,
						message: '请选择收支调整类型',
						trigger: 'change'
					}],
					money: [{
						required: true,
						trigger: 'blur',
						validator: validMoney
					}]
				}
			};
		},
		//新增
		created(){
           console.log(this.$route.query.id)
		},
		beforeMount() {
			this.mod = localStorage.getItem('mlmzDistributionModel');
			this.initTime();
			this.getList();
		},
		methods: {
			initTime() {
				var dt = new Date();
				var year = dt.getFullYear();
				var month = dt.getMonth() + 1;
				var day = dt.getDate();
				this.startTime = [year, month, 1].join('-') + ' 00:00:00';
				this.endTime = [year, month, day].join('-') + ' 23:59:59';
			},
			async getList() {
				try {
					const res = await membercommissionbalancerecordpagelist({
						MemberId:this.$route.query.id,
						Keywords: this.keywords,
						Skip: (this.pageIndex - 1) * this.pageSize,
						Take: this.pageSize,
						StartTime: this.startTime,
						EndTime: this.endTime,
					})

					if (res.IsSuccess) {
						this.list = res.Result.Results;
						this.total = res.Result.Total;
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			handleSearch() {
				this.pageIndex = 1;
				this.getList()
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList()
			},
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.getList()
			},

			showEdit() {
				this.visible = true;
				this.ruleForm.phone = '';
				this.ruleForm.type = '';
				this.ruleForm.money = undefined;
				this.ruleForm.remark = '';
				this.$nextTick(() => {
					this.$refs['ruleForm'].clearValidate();
				})

			},
			handleSubmit() {

				if (!validatePhone(this.ruleForm.phone)) {
					this.$message.error('请输入正确手机号');
					return;
				}

				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.validMember().then(res => {

							var str = `是否确认给推手【${res.Name || res.WxNickname}，${res.Phone}】
										<span class="red">${this.ruleForm.type == 9 ? `充值`:`扣减`}</span>
										可提现收益<span class="red">${this.ruleForm.money}</span>元？`;
							if (this.ruleForm.type == 9) {
								str += `充值成功后，推手即<span class="red">可提现该收益</span>`
							}

							this.$confirm(str, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
								dangerouslyUseHTMLString: true
							}).then(() => {
								this.sureSubmit(res.Id)
							}).catch(() => {});

						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			validMember() {
				return new Promise(async (reslove, reject) => {
					try {
						const res = await memberinfobyphone({
							Phone: this.ruleForm.phone
						})

						if (res.Result.Id) {
							reslove(res.Result);
						} else {
							this.$message.error('手机号非推手，请重新输入')
							reject();
						}
					} catch (e) {
						this.$message.error('手机号非推手，请重新输入')
						reject();
					}
				})
			},
			async sureSubmit(id) {
				try {
					const res = await memberCommissionChange({
						MemberId: id,
						BalanceRecordType: this.ruleForm.type,
						ChangeBalance: this.ruleForm.money,
						Remark: this.ruleForm.remark
					})
					this.$message.success('操作成功');
					this.visible = false;
					this.getList()
				} catch (e) {
					//TODO handle the exception
				}
			},
			exportFn() {
				let url = this.exportUrl + '/pc/commission/membercommissionbalancerecordexport?' +
					'&Keywords=' + this.keywords +
					'&StartTime=' + (this.startTime || '') +
					'&EndTime=' + (this.endTime || '')+
					'&MemberId=' + (this.$route.query.id || '');
				window.open(url);
			}
		}
	};
</script>

<style lang="less" scoped>
	::v-deep .el-input-number .el-input__inner {
		text-align: left;
	}

	.member-info {
		display: flex;
		align-items: center;

		.hd {
			flex: 0 0 auto;
			width: 40px;
			height: 40px;
			overflow: hidden;
			border-radius: 4px;

			img {
				display: block;
				width: 100%;
			}
		}

		.info {
			flex: 1 1 auto;
			overflow: hidden;
		}

		.name {
			font-size: 14px;
			margin-left: 10px;
			line-height: 18px;
		}
	}

	.pagination-wraper {
		display: flex;
		justify-content: flex-end;
		margin-top: 15px;
		margin-right: 20px;
	}
</style>
